<template>
    <div class="survey" ref="survey_outer">
        <div v-if="current_page !== 'survey_start'" class="progress" :class="{'progress--full': current_page === 'survey_end', 'progress--hidden': !show_progress_bar()}">
            <div class="progress__inner">
                <div class="progress__bar" :style="'width: ' + percent_complete + '%;'">
                    <div class="sr-only">The survey is {{percent_complete}} percent complete.</div>
                </div>
            </div>
            <p aria-hidden="true" class="progress__start">0&#37;</p>
            <p aria-hidden="true" class="progress__complete">100&#37;</p>
        </div>

        <transition name="fade" mode="out-in">
            <div class="survey-container" ref='survey_container' :class="[
                {'survey-container--has-background': current_page !== 'survey_code' && current_page !== 'loading_question' && current_page !== 'survey_message_only' && current_page !== '',
                'survey-container--processing-input': processing_input,
                'survey-container--error-shake': error_shake},
                'survey-container--page-' + current_page
            ]" :key="current_page + '__' + current_survey_path">
                <div class="messages" v-if="messages" ref="messages">
                    <transition-group name="fadeshrink" mode="out-in">
                        <div :style="message.alert ? '' : 'display: none;'" :class="'message message--' + message.type" v-for="(message, index) in messages" :key="message.key">
                            <div :role="message.alert ? 'alert' : ''" class="message__text"><span class="sr-only">{{message.type}} message: </span><span v-html="message.text"></span></div>
                            <button aria-label="close message" v-if="message.allow_delete && current_page !== 'survey_message_only'" class="message__close"
                                                              v-on:click.prevent="remove_message(index)">+</button>
                        </div>
                    </transition-group>
                </div>
                <div v-if="is_test || is_admin_override" class="survey-flags">
                    <div v-if="is_test" class="survey-flags__tag survey-flags__tag--test">Test Mode</div>
                    <div v-if="is_admin_override" class="survey-flags__tag survey-flags__tag--admin-override">Admin Override</div>
                </div>
                <div class="survey-question-loading" v-if="current_page === 'loading_question' || current_page === ''"
                     key="0">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                         aria-hidden="true" focusable="false" width="1em" height="1em"
                         preserveAspectRatio="xMidYMid meet" viewBox="0 0 1664 1728"
                         class="icon:fa-spinner survey-question-loading__spinner" style="transform: rotate(360deg);">
                        <path d="M462 1394q0 53-37.5 90.5T334 1522q-52 0-90-38t-38-90q0-53 37.5-90.5T334 1266t90.5 37.5T462 1394zm498 206q0 53-37.5 90.5T832 1728t-90.5-37.5T704 1600t37.5-90.5T832 1472t90.5 37.5T960 1600zM256 896q0 53-37.5 90.5T128 1024t-90.5-37.5T0 896t37.5-90.5T128 768t90.5 37.5T256 896zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5T1202 1394t37.5-90.5t90.5-37.5t90.5 37.5t37.5 90.5zM494 398q0 66-47 113t-113 47t-113-47t-47-113t47-113t113-47t113 47t47 113zm1170 498q0 53-37.5 90.5T1536 1024t-90.5-37.5T1408 896t37.5-90.5T1536 768t90.5 37.5T1664 896zm-640-704q0 80-56 136t-136 56t-136-56t-56-136t56-136T832 0t136 56t56 136zm530 206q0 93-66 158.5T1330 622q-93 0-158.5-65.5T1106 398q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                              fill="currentColor"></path>
                    </svg>
                </div>
                <div class="survey-code" v-else-if="current_page === 'survey_code'" key="1">
                    <h2 v-html="process_text(code_input.title)"></h2>
                    <input v-model="survey_code" placeholder="Enter Code">
                    <button v-on:click.prevent="validate_code_and_start">{{ code_input.action_button }}</button>
                </div>
                <div class="survey-consent" v-else-if="current_page === 'survey_consent'" key="3">
                    <div class="survey-consent__question" v-html="survey_settings.consent.text"></div>
                    <div class="survey-consent__accept"><input :disabled="consent_given" v-model="user_consent_response" value="accept" type="radio" id="survey-consent-accept"><label for="survey-consent-accept" v-html="survey_settings.consent.label_text__accept"></label></div>
                    <div class="survey-consent__reject"><input :disabled="consent_given" v-model="user_consent_response" value="reject" type="radio" id="survey-consent-reject"><label for="survey-consent-reject" v-html="survey_settings.consent.label_text__reject"></label></div>
                    <div class="question__actions nav-buttons">
                        <button class="back-button" v-on:click.prevent="prev_question">
                            {{survey_settings.prev_button_text}}
                        </button><button class="next-button" v-on:click.prevent="submit_consent">
                            {{survey_settings.next_button_text}}
                        </button>
                    </div>
                </div>
                <div class="survey-start" v-else-if="current_page === 'survey_start'" key="4">
                    <div class="survey-start__logo" v-if="survey_settings.logo">
                        <img :src="survey_settings.logo" :alt="survey_settings.logo_alt_text">
                    </div>
                    <h2>{{process_text(survey_settings.title)}}</h2>
                    <p v-html="survey_settings.landing_page_text"></p>
                    <button class="button--start-survey" v-on:click.prevent="start_survey">{{ survey_settings.start_button_text }}</button>
                </div>
                <div class="survey-end" v-else-if="current_page === 'survey_end'" key="5">
                    <h2>{{process_text(survey_settings.title)}}</h2>
                    <p v-if="survey_completed_before_start" v-html="survey_settings.survey_already_complete"></p>
                    <p v-html="survey_settings.thanks_page_text"></p>
                </div>
                <div class="survey-message-only" v-else-if="current_page === 'survey_message_only'" key="7">
                </div>
                <div class="survey-question" v-else key="6">
                    <question :processing_input="processing_input" :question_data="question_data"
                              :survey_settings="survey_settings" :form_values_preset="form_values" @update_parent_values="update_form_values"></question>
                    <div class="question__actions">
                        <button class="back-button" v-on:click.prevent="prev_question">
                            {{survey_settings.prev_button_text}}
                        </button><button class="next-button" v-on:click.prevent="submit_question">
                            {{survey_settings.next_button_text}}
                        </button>
                    </div>
                </div>
            </div>
        </transition>

    </div>
</template>

<script>
  import question from './components/question.vue'
  import config from './config.json'

  import {
    get_question_type,
    order_options,
    stripHTML,
    process_text,
    seeded_random,
    get_cookie,
    set_cookie,
    handleErrors, conditional_log, show_error, show_message,
  } from "./utilities";

  export default {
    data: () => {
      return {
        question_data: {},
        survey_code: "",
        sample_hash: "",
        current_question_hash: "",
        current_survey_path: "",
        survey_completed_before_start: false,
        fetched_survey_code_settings: false,
        random_seed: "",
        percent_complete: 0,
        history: {},
        current_page: "",
        next_url: "",
        processing_input: false,
        survey_settings: {},
        form_values: null,
        messages: [],
        is_test: false,
        is_admin_override: false,
        consent_required: false,
        generic_error_with_request: "",
        answer_history: [],
        user_consent_response: null,
        show_logging: false,
        consent_denied: false,
        consent_given: false,
        read_only_consent: false,
        error_shake: false,
        show_intro_text_on_next_question: false,
        latest_intro_text: '',
      }
    },
    components: {
      question: question,
    },
    created() {
      this.base_api_url = config.base_api_url;
      this.code_input = config.code_input;
      this.invalid_code_page = config.invalid_code_page;
      this.generic_error_with_request = config.generic_error_with_request || "There was an error with your request. Please try again later.";
      this.question_required_error = config.question_required_error || "This question is required.";

      this.api_paths = config.api_paths;
      this.api_paths.code_lookup = this.api_paths.code_lookup || '/api/code/{code}';
      this.api_paths.get_question = this.api_paths.get_question || '/api/question/{question_hash}/{sample_hash}';
      this.api_paths.api_response = this.api_paths.api_response || '/api/response';
      this.api_paths.api_path = this.api_paths.api_path || '/api/path';
      this.api_paths.consent = this.api_paths.consent || '/api/consent';

      const urlParams = new URLSearchParams(window.location.search);
      this.show_logging = Number(urlParams.get('debug')) || false;
      this.log('Debugging enabled')

      this.code_regex = new RegExp(config.code_validation_regex, 'i')
      if (window.location.pathname === '/') {
        this.current_page = "survey_code"
      } else {
        this.load_state_from_url()
      }
    },
    methods: {
      load_state_from_url: function () {
        this.log('Loading state')
        let url_params = window.location.pathname.split('/');
        let code = url_params[1].toLowerCase()
        this.log('load_state_from_url', url_params)

        if (!code.toLowerCase().match(this.code_regex)) {
          this.current_page = "survey_code"
        } else {
          this.survey_code = code;
          if (url_params.length === 6 && url_params[2] === 'q') {
            let cb = (_) => {
              this.next_url = this.base_api_url + this.api_paths.get_question
                .replace('{question_hash}', url_params[4])
                .replace('{sample_hash}', url_params[5])
              this.current_page = "loading_question"
              this.next_question((_, on_finish) => {
                this.current_survey_path = url_params[3]
                this.current_question_hash = url_params[4]
                this.sample_hash = url_params[5]
                this.retrieve_existing_answer_data(() => {
                  on_finish()
                })
              })
            }
            if (this.fetched_survey_code_settings) {
              cb()
            } else {
              this.retrieve_survey_settings(cb)
            }
          }
          else if (url_params[2] === 'complete') {
            let cb = (_) => {
              this.end_survey()
            }
            if (this.fetched_survey_code_settings) {
              cb()
            } else {
              this.retrieve_survey_settings(cb)
            }
          }
          else if (url_params[2] === 'consent') {
            let cb = (json) => {
              this.next_url = this.base_api_url + json.member_survey_status.next_question_path;
              this.current_survey_path = json.member_survey_status.path;
              if (!json.member_survey_status.next_question_path) {
                this.show_error(this.generic_error_with_request);
                this.log('There was an missing value for json.member_survey_status.next_question_path when loading the consent page.')
                this.processing_input = false
                return;
              }
              this.start_consent(true)
            }
            if (this.fetched_survey_code_settings) {
              cb()
            } else {
              this.retrieve_survey_settings(cb)
            }
          }
          else {
            if (this.fetched_survey_code_settings) {
              this.current_page = 'survey_start';
            } else {
              this.goto_start_survey()
            }
          }
        }
      },
      update_form_values: function (values) {
        this.form_values = values
      },
      validate_code_and_start: function () {
        this.clear_messages()
        if (!this.survey_code.toLowerCase().match(this.code_regex)) {
          this.show_error('invalid code')
          return;
        }
        this.goto_start_survey()
      },
      goto_start_survey: function () {
        if (!this.survey_code.toLowerCase().match(this.code_regex)) {
          this.show_error(this.invalid_code_page.title)
          return;
        }
        this.processing_input = true;

        let cb = (json) => {
          this.next_url = this.base_api_url + json.member_survey_status.next_question_path;
          if (!json.member_survey_status.next_question_path) {
            this.show_error(this.generic_error_with_request);
            this.log('There was an missing value for json.member_survey_status.next_question_path when starting the survey.')
            this.processing_input = false
            return;
          }
          this.current_page = "survey_start"

          this.latest_intro_text = json.member_survey_status.intro_text
          this.show_intro_text_on_next_question = typeof json.member_survey_status.intro_text !== 'undefined' && Boolean(json.member_survey_status.intro_text)

          this.current_survey_path = json.member_survey_status.path;
          history.pushState({
            current_page: 'survey_start',
            survey_code: this.survey_code.toLowerCase()
          }, '', '/' + this.survey_code.toLowerCase());
        }
        this.retrieve_survey_settings(cb)
      },
      retrieve_survey_settings: function (callback) {
        let url = new URL(this.base_api_url + this.api_paths.code_lookup.replace('{code}', this.survey_code));
        // get current survey state
        fetch(url.toString(), {credentials: "include"})
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            this.fetched_survey_code_settings = true;
            this.log('Received survey data', json)
            if (typeof json.status !== 'undefined' && !Number(json.status)) {
              this.processing_input = false;
              if (json.error_code === 'sample_member_canceled') {
                this.current_page = 'survey_message_only'
                this.show_error(json.message, false)
              }
              else {
                this.current_page = 'survey_code';
                this.show_error(json.message)
              }
              return;
            }

            if (typeof json.is_test !== 'undefined' && Number(json.is_test)) {
              this.is_test = true
            }
            if (typeof json.admin_override !== 'undefined' && Number(json.admin_override)) {
              this.is_admin_override = true
            }
            if (typeof json.debug !== 'undefined' && Number(json.debug)) {
              this.show_logging = true
            }

            this.survey_settings = {
              // 'title': json.sample_status.survey.title[0].value,
              'landing_page_text': json.survey_intro,
              'thanks_page_text': json.survey_thanks,
              'survey_already_complete': json.survey_already_complete,
              'start_button_text': json.start_button,
              'prev_button_text': json.prev_button,
              'next_button_text': json.next_button,
              'logo': json.logo,
              'logo_alt_text': json.logo_alt_text,
            }
            this.generic_error_with_request = json.generic_error_with_request || this.generic_error_with_request
            this.question_required_error = json.question_required_error || this.question_required_error

            this.random_seed = json.random_seed;
            this.sample_hash = json.sample_hash;

            if (typeof json.member_survey_status.complete !== 'undefined' && Number(json.member_survey_status.complete)) {
              this.survey_completed_before_start = true;
              this.end_survey()
              return;
            }
            this.latest_intro_text = json.intro_text
            this.show_intro_text_on_next_question = typeof json.intro_text !== 'undefined' && Boolean(json.intro_text)

            this.percent_complete = json.member_survey_status.progress;
            this.processing_input = false;

            this.consent_required = false;
            if (typeof json.consent !== 'undefined' && Object.keys(json.consent).length > 0) {
              this.consent_required = true
              this.consent_given = Boolean(Number(json.consent.consent_given));
              if (this.consent_given) {
                this.user_consent_response = 'accept';
              }
              this.survey_settings.consent = json.consent;
            }

            callback(json)
          }).catch((e) => {
          this.show_error(this.generic_error_with_request)
        });
      },
      submit_consent: function() {
        if (this.consent_given) {
          this.next_question();
          return;
        }
        if (this.consent_denied) {
          this.current_page = 'survey_code';
          return;
        }

        if (this.user_consent_response === 'accept') {
          this.current_page = "loading_question"
          this.give_consent()
        }
        else if (this.user_consent_response === 'reject') {
          this.deny_consent()
        }
        else {
          this.clear_messages();
          this.show_error('You must select an option.')
        }
      },
      give_consent: function () {
        this.processing_input = true;
        this.clear_messages();
        let url = new URL(this.base_api_url + this.api_paths.consent);
        fetch(url.toString(), {
          "method": "POST",
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
          },
          "body": JSON.stringify({
            code: this.survey_code,
            consent: 1,
          })
        })
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            if (!Number(json.status)) {
              this.processing_input = false
              this.current_page = 'survey_consent';
              this.show_error(json.message)
              return;
            }
            this.consent_given = true;
            this.processing_input = false;
            this.next_question()
          }).catch((e) => {
          this.show_error(this.generic_error_with_request)
        })
      },
      deny_consent: function () {
        this.processing_input = true;
        this.clear_messages();
        this.consent_denied = true;
        let url = new URL(this.base_api_url + this.api_paths.consent);
        fetch(url.toString(), {
          "method": "POST",
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
          },
          "body": JSON.stringify({
            code: this.survey_code,
            consent: 0,
          })
        })
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            this.processing_input = false;
            if (Number(json.status)) {
              this.show_message(json.message, false)
              this.consent_required = false;
              this.current_page = 'survey_message_only';
            } else {
              this.consent_denied = false;
              this.show_error(json.message)
              this.current_page = 'survey_consent';
            }
          }).catch((e) => {
          this.consent_denied = false;
          this.current_page = 'survey_consent';
          this.show_error(this.generic_error_with_request)
        })
      },
      start_consent: function(from_reload) {
        if (typeof from_reload === 'undefined') {
          from_reload = false;
        }
        if (this.consent_given && !from_reload) {
          this.next_question();
          return;
        }

        if (typeof this.survey_settings['consent'] === 'undefined') {
          this.log("Consent not active for this sample.")
          this.next_question()
          return;
        }

        if (this.consent_denied) {
          return;
        }

        this.current_page = 'survey_consent';
        let state_data = {current_page: this.current_page, survey_code: this.survey_code.toLowerCase()}
        if (JSON.stringify(history.state) !== JSON.stringify(state_data)) {
          history.pushState(state_data, '',
            '/' + this.survey_code.toLowerCase() +
            '/consent');
        }
      },
      goto_code_input: function () {
        this.current_page = "survey_code"
      },
      start_survey: function () {
        this.clear_messages()
        this.current_page = 'loading_question'

        if (typeof this.survey_settings['consent'] !== 'undefined') {
          this.start_consent()
          return;
        }

        this.next_question()
      },
      end_survey: function (json) {
        this.percent_complete = 100;
        this.processing_input = false;
        this.current_page = "survey_end";

        let state_data = {current_page: this.current_page, survey_code: this.survey_code.toLowerCase()}
        if (JSON.stringify(history.state) !== JSON.stringify(state_data)) {
          history.pushState(state_data, '',
            '/' + this.survey_code.toLowerCase() +
            '/complete');
        }
      },
      process_text: process_text,
      stripHTML: stripHTML,
      order_options: order_options,
      get_question_type: get_question_type,
      next_question: function (cb) {
        fetch(this.next_url, {credentials: "include"})
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            if (!Number(json.status)) {
              this.processing_input = false
              this.current_page = 'question';
              this.show_error(json.message || this.generic_error_with_request);
              return;
            }

            this.question_data = json;

            if (this.question_data.question.hasOwnProperty("answer_options_groups")) {
              this.question_data.answer_options_sorted = this.order_options('answer_options_groups');
            }
            if (this.question_data.question.hasOwnProperty("question_options_groups")) {
              this.question_data.question_options_sorted = this.order_options('question_options_groups');
            }

            if (['checkboxes', 'radios'].indexOf(this.question_data.question.question_type) !== -1) {
              this.form_values = {};
            } else if (['ranking'].indexOf(this.question_data.question.question_type) !== -1) {
              this.form_values = this.question_data.answer_options_sorted;
            } else if (['matrix', 'yesno_radios_simple', 'matrix_checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
              this.form_values = {};
            } else if (['checkbox'].indexOf(this.question_data.question.question_type) !== -1) {
              this.form_values = false;
            } else {
              // select, textfield, textarea
              this.form_values = '';
            }
            this.current_question_hash = json.question.question_hash

            this.processing_input = false
            this.question_start_time = new Date();

            let on_finish = () => {
              if (this.show_intro_text_on_next_question) {
                this.question_data.intro_text = this.latest_intro_text;
              }

              let state_data = {
                current_page: 'question',
                survey_code: this.survey_code.toLowerCase(),
                current_survey_path: this.current_survey_path,
                current_question_hash: this.current_question_hash,
                sample_hash: this.sample_hash
              }

              if (JSON.stringify(history.state) !== JSON.stringify(state_data)) {
                history.pushState(state_data, '',
                    '/' + this.survey_code.toLowerCase() +
                    '/q/' + this.current_survey_path +
                    '/' + this.current_question_hash +
                    '/' + this.sample_hash);
              }

              const answer_id = this.get_current_answer_history_id();
              const answer_index = this.answer_history.findIndex(a => a.id === answer_id)
              if (answer_index !== -1) {
                this.form_values = this.answer_history[answer_index].form_values
                this.log('Found answer_history value, overriding existing values, setting this.form_values', this.form_values)
              }
              else {
                this.log('No answer_history found, leaving as-is.')
              }

              this.current_page = 'question';
            }
            if (typeof cb === 'function') {
              cb(json, on_finish)
            }
            else {
              on_finish()
            }
          }).catch((e) => {
          this.log('Error in fetching next question', e)
          this.show_error(this.generic_error_with_request)
          this.processing_input = false
          this.current_page = 'question';
        })

      },
      retrieve_existing_answer_data: function(cb) {
        let url = new URL(this.base_api_url + this.api_paths.api_path);
        url.searchParams.append('code', this.survey_code)
        url.searchParams.append('path', this.current_survey_path)
        fetch(url.toString(), {
          "method": "GET",
          credentials: "include"
        })
        .then(handleErrors)
        .then(res => res.json())
        .then(json => {
          this.log('json', json);
          this.log('continuing', this.form_values)
          this.form_values = {}
          // text answer
          this.latest_intro_text = json.intro_text
          this.show_intro_text_on_next_question = typeof json.intro_text !== 'undefined' && Boolean(json.intro_text)

          this.process_existing_answer_data(json)
          if (typeof cb === 'function') {
            cb(json)
          }
        }).catch((e) => {
          this.log('Error in fetching existing path data', e)
          this.show_error(this.generic_error_with_request)

          if (typeof cb === 'function') {
            cb({error: e})
          }
        })

      },
      process_existing_answer_data: function(json, cb) {
        this.log('process_existing_answer_data this.form_values', this.form_values)
        if (!json.answer) {
          this.log('process_existing_answer_data, no existing answer data found')

          if (typeof cb === 'function') {
            cb()
          }
          return;
        }
        else {
          this.log('process_existing_answer_data, json', json)
        }
        if (['select', 'textfield', 'textarea', 'matrix', 'yesno_radios_simple'].indexOf(this.question_data.question.question_type) !== -1) {
          this.form_values = json.answer
        }
        if (['radios'].indexOf(this.question_data.question.question_type) !== -1) {
          this.form_values['answer'] = json.answer
          this.form_values['answer_other'] = {};

          if (typeof json.answer_other === 'object' && Object.keys(json.answer_other).length > 0) {
            this.form_values['answer_other'] = json.answer_other
          }
        }
        if (['ranking'].indexOf(this.question_data.question.question_type) !== -1) {
          this.log('this.question_data.question_options_sorted', this.question_data);
          this.form_values = this.question_data.answer_options_sorted.sort((a, b) => {
            return json.answer.indexOf(a.value) - json.answer.indexOf(b.value)
          })
          this.log('this.form values', this.form_values)
        }

        // array of answers
        if (['checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          this.form_values['answer'] = {}
          this.form_values['answer_other'] = {};
          json.answer.forEach(a => this.form_values.answer[a] = true);
          if (typeof json.answer_other === 'object' && Object.keys(json.answer_other).length > 0) {
            this.form_values['answer_other'] = json.answer_other
          }
        }
        // array of answers
        if (['matrix_checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          this.form_values = {}

          this.question_data.question_options_sorted.forEach((row) => {
            this.form_values[row.value] = {};
            this.question_data.answer_options_sorted.forEach((column) => {
              this.form_values[row.value][column.value] = false;
            })
          })

          Object.keys(json.answer).forEach(row => {
            json.answer[row].forEach(column => {
              this.form_values[row][column] = true
            })
          });
        }

        if (typeof cb === 'function') {
          cb()
        }
      },
      show_progress_bar: function () {
        return ['survey_start', 'survey_end', 'question', 'loading_question', 'survey_consent'].indexOf(this.current_page) !== -1
      },
      get_api_response_params() {
        let params = {
          code: this.survey_code.toLowerCase(),
          path: this.current_survey_path,
          question_time: parseInt(Math.round((new Date() - this.question_start_time) / 1000)),
        };
        if (['checkboxes', 'ranking', 'radios', 'matrix', 'select', 'yesno_radios_simple', 'matrix_checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          params['order'] = this.order_options('answer_options_groups').map(answer => answer.value)
        }
        if (['matrix', 'yesno_radios_simple', 'matrix_checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          params['question_order'] = this.order_options('question_options_groups').map(answer => answer.value)
        }

        params['ranking'] = ['ranking'].indexOf(this.question_data.question.question_type) !== -1 ? 1 : 0

        // text answer
        if (['select', 'textfield', 'textarea', 'matrix', 'yesno_radios_simple'].indexOf(this.question_data.question.question_type) !== -1) {
          params['answer'] = this.form_values;
        }
        if (['radios'].indexOf(this.question_data.question.question_type) !== -1) {
          params['answer'] = this.form_values.answer;
          if (typeof this.form_values.answer_other === 'object' && Object.keys(this.form_values.answer_other).length > 0 && typeof this.form_values.answer_other[this.form_values.answer] !== 'undefined') {
            params['answer_other'] = this.form_values.answer_other[this.form_values.answer];
          }
        }
        // ranked array
        if (['ranking'].indexOf(this.question_data.question.question_type) !== -1) {
          params['answer'] = this.form_values.map(a => a.value);
        }
        // array of answers
        if (['checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          params['answer'] = [];
          Object.keys(this.form_values.answer).forEach((v) => {
            if (this.form_values.answer[v]) {
              params.answer.push(v);
            }
          })
          if (typeof this.form_values.answer_other === 'object' && Object.keys(this.form_values.answer_other).length > 0) {
            params['answer_other'] = {}
            Object.keys(this.form_values.answer_other).forEach((ak) => {
              if (params['answer'].indexOf(ak) !== -1) {
                params['answer_other'][ak] = this.form_values.answer_other[ak];
              }
            })
          }
        }
        if (['matrix_checkboxes'].indexOf(this.question_data.question.question_type) !== -1) {
          params['answer'] = {};
          Object.keys(this.form_values).forEach((row) => {
            params['answer'][row] = [];
            Object.keys(this.form_values[row]).forEach((column) => {
              if (this.form_values[row][column]) {
                params['answer'][row].push(column)
              }
            })
          })
        }
        this.log('Params to send', params);
        return params
      },
      submit_question: function () {
        this.processing_input = true;
        this.clear_messages()

        let url = new URL(this.base_api_url + this.api_paths.api_response);
        let params = this.get_api_response_params();
        if (Number(this.question_data.required)) {
          if ((typeof params.answer === 'string' && params.answer.length < 1) ||
              (typeof params.answer === 'object' && params.answer !== null && Object.keys(params.answer).length < 1 &&
                  (
                      Object.keys(params.answer).every(k => typeof k === 'string') ||
                      Object.keys(params.answer).every(k => typeof k === 'object' && Object.keys(params.answer[k]).length < 1)
                  )
              )
          ) {
            this.show_error(this.question_required_error)
            this.processing_input = false;
            return;
          }
        }
        url.searchParams.append('cache', Math.round(Math.random() * 1000000000))
        fetch(url.toString(), {
          "method": "POST",
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
          },
          "body": JSON.stringify(params)
        })
          .then(handleErrors)
          .then(res => res.json())
          .then(json => {
            if (!Number(json.status)) {
              this.processing_input = false
              this.error_shake = true;
              setTimeout(() => {this.error_shake = false}, 1000);
              this.show_error(json.message);
              return;
            }

            const answer_id = this.get_current_answer_history_id();
            const answer_index = this.answer_history.findIndex(a => a.id === answer_id)
            const answer_entry = {
              'id': answer_id,
              'params': params,
              'form_values': this.form_values
            }
            if (answer_index !== -1) {
              this.answer_history[answer_index] = answer_entry
            } else {
              this.answer_history.push(answer_entry)
            }

            this.current_page = "loading_question"

            this.percent_complete = json.progress;
            if (json.hasOwnProperty('complete') && json.complete) {
              this.end_survey(json)
              this.processing_input = false
              return;
            }

            if (!json.next_question_path) {
              this.show_error(this.generic_error_with_request);
              this.log('There was an missing value for json.next_question_path when submitting the question.')
              this.processing_input = false
              this.current_page = 'question';
              return;
            }
            this.next_url = this.base_api_url + json.next_question_path;

            this.next_question((_, on_finish) => {
              this.current_survey_path = json.path;
              this.latest_intro_text = json.intro_text
              this.show_intro_text_on_next_question = typeof json.intro_text !== 'undefined' && Boolean(json.intro_text)
              this.process_existing_answer_data(json, on_finish)
            })
          }).catch((e) => {
          this.log('Error in submitting response', e)
          this.show_error(this.generic_error_with_request)
          this.processing_input = false
          this.current_page = 'question';
        })
      },
      get_current_answer_history_id: function() {
        return this.survey_code.toLowerCase() +
          '__' + this.current_survey_path +
          '__' + this.current_question_hash +
          '__' + this.sample_hash;
      },
      show_error: show_error,
      show_message: show_message,
      prev_question: function () {
        this.clear_messages();
        window.history.back();
      },
      seeded_random: seeded_random,
      get_cookie: get_cookie,
      set_cookie: set_cookie,
      log: conditional_log,
      clear_messages: function () {
        this.messages.forEach((message, index) => {
          this.remove_message(index)
        })
      },
      remove_message: function(index) {
        this.messages[index].alert = false;
        this.$nextTick(() => {
          // remove the message after update that way screen readers don't read out the error message on removal due to
          // how vue removes elements.
          this.messages.splice(index, 1);
        })
      }
    },
    mounted() {
      window.onpopstate = () => {
        this.load_state_from_url()
      }
    }
  }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

    body {
        margin: 0;
        padding: 0;
        font-family: 'Open Sans', sans-serif;
    }

    /* Survey Consent */
    .survey-consent__question p {
        margin: 0.75em 0;
        text-align: left;
        color: #232222;
    }

    /* Tablet and Desktop */
    @media (min-width: 600px) {
        body {
            background-color: #f2f2f2;
            font-size: 18px;
        }
    }

    /* Mobile */

    @media (max-width: 600px) {
        body {
            background-color: #ffffff;
            font-size: 18px;
        }
    }
</style>

<style>

    /* Page Layout and Text Element Styling*/

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.25em;
    }

    h3 {
        font-size: 1.15em;
    }

    h1, h2, h3, h4, h5 {
        font-weight: 600;
        color: #343333;
        letter-spacing: normal;
    }

    /* Survey Code/Start Page */
    .survey-code {
        text-align: center;
    }

    .survey-code input {
        display: block;
        padding: 5px 0;
        font-size: 26px;
        font-family: Verdana, 'Open Sans', sans-serif;
        color: #343333;
        border-radius: 20px;
        border: 2px solid #1172ba;
        text-align: center;
        width: 250px;
        margin: 60px auto 40px auto;
        outline: none;
    }

    .survey-code input:focus {
        transition: all 200ms ease-out;
        box-shadow: 0 4px 8px 0 rgba(17, 114, 186, 0.2), 0 6px 20px 0 rgba(17, 114, 186, 0.19);
    }

    .survey-code input::placeholder {
        color: #626262;
    }

    /* Survey Start */
    .survey-start__logo img {
        width: auto;
        max-width: 350px;
        max-height: 350px;
        display: block;
        margin: 0 auto;
        padding-bottom: 25px;
    }

    .survey-start__consent p {
        margin: 0.75em 0;
        text-align: left;
    }

    .survey-consent__accept, .survey-consent__reject {
        text-align: left;
        margin: 0.75em 0;
        color: #232222;
        letter-spacing: 0.5pt;
    }

    .survey-consent__accept input, .survey-consent__reject input {
        margin-right: 20px;
    }

    /* Survey Intro Buttons */
    .survey-code button, .survey-start button {
        display: block;
        padding: 5px;
        font-size: 26px;
        font-family: Verdana, 'Open Sans', sans-serif;
        color: #ffffff;
        border-radius: 18px;
        border: 2px solid #1172ba;
        background-color: #1172ba;
        width: 150px;
        margin: 20px auto;
        outline: none;
        cursor: pointer;
    }

    .survey-start button {
        width: 200px;
    }

    /* Navigation Buttons */
    .question__actions {
        margin: 20px auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }

    .question__actions button {
        outline: none;
        margin: 0 2px;
    }

    .back-button {
        color: #1172ba;
        background-color: #ffffff;
        border: 2px solid #ffffff;
        cursor: pointer;
    }

    .next-button {
        color: #ffffff;
        background-color: #1172ba;
        border: 2px solid #1172ba;
        cursor: pointer;
    }

    .back-button:focus {
        transition: all 200ms ease-out;
        box-shadow: 0 4px 8px 0 rgba(17, 114, 186, 0.2), 0 6px 20px 0 rgba(17, 114, 186, 0.19);
        border: 2px solid #1172ba;
    }

    .next-button:focus, .survey-start button:focus, .survey-code button:focus {
        transition: all 200ms ease-out;
        box-shadow: 0 4px 8px 0 rgba(0, 94, 166, 0.3), 0 6px 20px 0 rgba(0, 94, 166, 0.29);
    }

    /* Progress Bar */
    .progress {
        margin: 25px auto 50px auto;
        opacity: 1;
        transition: opacity 0.25s ease;
    }

    .progress p {
        margin-top: 5px;
        margin-bottom: 0;
        color: #1172ba;
    }

    .progress__start {
        float: left;
    }

    .progress__complete {
        float: right;
    }

    .progress__inner {
        background-color: #c4dcee;
        border-radius: 50px;
        color: #1172ba;
        position: relative;
        overflow: hidden;
    }

    .progress__inner::before {
        content: '';
        position: absolute;
        height: inherit;
        width: calc(25% - 4px);
        left: calc(25% - 2px);
        z-index: 10;
    }

    .progress__inner::after {
        content: '';
        position: absolute;
        height: inherit;
        width: calc(25% - 4px);
        right: calc(25% - 2px);
        top: 0;
        z-index: 10;
    }

    .progress__bar {
        height: inherit;
        background-color: #1172ba;
        transition: width 0.5s ease;
    }

    .progress--full p {
        color: #39b54a;
    }

    .progress--full .progress__bar {
        background-color: #39b54a;
        width: 100%;
    }

    .progress--hidden {
        opacity: 0;
    }

    /* Messages */
    .message--error {
        background-color: #fcded4;
        color: #f15a24;
    }

    .message--notice {
        background-color: #e7fcd4;
        color: #39b54a;
    }

    .message__text {
        width: calc(100% - 60px);
        padding-right: 10px;
    }

    .message__text, .message__close {
        display: inline-block;
        vertical-align: middle;
    }

    .message__close {
        border-radius: 50px;
        color: inherit;
        font-weight: bold;
        background-color: inherit;
        transform: rotate(45deg);
        outline: none;
        cursor: pointer;
        font-size: 20px;
        width: 36px;
        height: 36px;
    }

    .message--error .message__close {
        border: 4px solid #f15a24;
    }

    .message--notice .message__close {
        border: 4px solid #39b54a;
    }

    /* Survey animations */
    .fade-enter-to, .fade-leave {
        opacity: 1;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s ease;
        transition-property: opacity, height, max-height;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    /* Survey animations */
    .fadeshrink-enter-to, .fadeshrink-leave {
        opacity: 1;
        max-height: 200px;
    }

    .fadeshrink-enter-active, .fadeshrink-leave-active {
        transition: opacity 0.5s ease;
        transition-property: opacity, height, max-height;
    }

    .fadeshrink-enter, .fadeshrink-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }
    .fadeshrink-leave-to {
      max-height: 0;
    }

    /* Survey Loading */

    .survey-question-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
    }

    .survey-question-loading__spinner {
        animation: 2s spin infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* Tablet and Desktop */

    @media (min-width: 600px) {
        /* Page Layout and Text Element Styling*/
        .survey {
            width: 70%;
            max-width: 700px;
            margin: 50px auto;
        }

        .survey-container {
            padding: 25px 50px;
            position: relative;
        }

        .survey-flags {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
        }

        .survey-flags__tag {
            font-size: 14px;
            padding: 4px 8px;
            opacity: 0.7;
        }

        .survey-flags__tag--test {
            background: #d4e8fc;
        }

        .survey-flags__tag--admin-override {
            background: #ffdce3;
        }

        .survey-container--has-background {
            background-color: #fff;
            position: relative;
        }

        .survey-container--page-question:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            background-color: rgba(255, 255, 255, 0.5);
            opacity: 0;
            z-index: 9999;
            transition: 0.1s ease opacity;
            will-change: opacity;
        }

        .survey-container--processing-input:after {
            opacity: 1;
            pointer-events: auto;
        }

        .survey-question-loading__spinner {
            width: 100px;
            height: 100px;
        }

        h2 {
            margin: 10px 0;
            padding: 0;
        }

        .question__info p {
            margin: 10px 0;
            padding: 0;
        }

        /* Survey Intro Buttons */
        .survey-start button {
            margin-top: 50px;
        }

        /* Navigation Buttons */
        .question__actions button {
            border-radius: 10px;
            padding: 5px 10px;
            font-size: 24px;
            font-family: Verdana, 'Open Sans', sans-serif;
        }

        /* Progress Bar */
        .progress {
            width: 90%;
        }

        .progress__inner {
            height: 20px;
        }

        .progress__inner::before {
            border-right: 4px solid #f2f2f2;
            border-left: 4px solid #f2f2f2;
        }

        .progress__inner::after {
            border-right: 4px solid #f2f2f2;
        }

        /* Error Message */
        .message {
            width: 100%;
            padding: 25px 50px;
            margin-left: -50px;
            margin-top: -25px;
            margin-bottom: 25px;
            text-align: left;
            font-size: 20px;
        }
    }

    /* Mobile */

    @media (max-width: 600px) {
        /* Page Layout and Text Element Styling */
        .survey {
            margin: 0;
            padding: 20px 20px;
        }

        h2 {
            margin: 20px 0;
        }

        .question__info p {
            margin: 10px 0;
        }

        /* Survey Code/Start Page */
        .survey-start {
            text-align: center;
        }

        .survey-code input {
            width: 100%;
            font-size: 26px;
            font-size: 26px;
            box-sizing: border-box;
        }

        .survey-start__logo img {
            box-sizing: border-box;
        }

        /* Survey Intro Buttons */

        .survey-start button {
            margin-top: 50px;
            border-radius: 20px;
        }

        /* Navigation Buttons */
        .question__actions button {
            padding: 15px 20px;
            font-size: 26px;
            font-family: Verdana, 'Open Sans', sans-serif;
            border-radius: 18px;
        }

        /* Progress Bar */
        .progress {
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
        }

        .progress__inner {
            height: 18px;
        }
        .progress__start, .progress__complete {
          font-size: 12px;
        }

        .progress__inner::before {
            border-right: 4px solid #ffffff;
            border-left: 4px solid #ffffff;
        }

        .progress__inner::after {
            border-right: 4px solid #ffffff;
        }

        /* Error Message */
        .message {
            width: 100%;
            box-sizing: border-box;
            padding: 15px;
            text-align: left;
            font-size: 20px;
        }

        .survey-question-loading__spinner {
            width: 80px;
            height: 80px;
        }
    }
    .survey-container--error-shake {
        -webkit-animation: error_shake 0.4s 1 linear;
        -moz-animation: error_shake 0.4s 1 linear;
        -o-animation: error_shake 0.4s 1 linear;
        animation: error_shake 0.4s 1 linear;
    }
    @-webkit-keyframes error_shake {
        0% { -webkit-transform: translate(30px); }
        20% { -webkit-transform: translate(-30px); }
        40% { -webkit-transform: translate(15px); }
        60% { -webkit-transform: translate(-15px); }
        80% { -webkit-transform: translate(8px); }
        100% { -webkit-transform: translate(0px); }
    }
    @-moz-keyframes error_shake {
        0% { -moz-transform: translate(30px); }
        20% { -moz-transform: translate(-30px); }
        40% { -moz-transform: translate(15px); }
        60% { -moz-transform: translate(-15px); }
        80% { -moz-transform: translate(8px); }
        100% { -moz-transform: translate(0px); }
    }
    @-o-keyframes error_shake {
        0% { -o-transform: translate(30px); }
        20% { -o-transform: translate(-30px); }
        40% { -o-transform: translate(15px); }
        60% { -o-transform: translate(-15px); }
        80% { -o-transform: translate(8px); }
        100% { -o-origin-transform: translate(0px); }
    }

</style>
