import Vue from 'vue';
import survey from './survey.vue';
import {updateScopeId} from "./utilities";
Vue.config.devtools = true

Vue.directive('scope', {
  bind: updateScopeId,
});

new Vue({
  el: '#vue_container',
  render: h => h(survey),
});