var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "survey_outer", staticClass: "survey" },
    [
      _vm.current_page !== "survey_start"
        ? _c(
            "div",
            {
              staticClass: "progress",
              class: {
                "progress--full": _vm.current_page === "survey_end",
                "progress--hidden": !_vm.show_progress_bar()
              }
            },
            [
              _c("div", { staticClass: "progress__inner" }, [
                _c(
                  "div",
                  {
                    staticClass: "progress__bar",
                    style: "width: " + _vm.percent_complete + "%;"
                  },
                  [
                    _c("div", { staticClass: "sr-only" }, [
                      _vm._v(
                        "The survey is " +
                          _vm._s(_vm.percent_complete) +
                          " percent complete."
                      )
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "progress__start",
                  attrs: { "aria-hidden": "true" }
                },
                [_vm._v("0%")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "progress__complete",
                  attrs: { "aria-hidden": "true" }
                },
                [_vm._v("100%")]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _c(
          "div",
          {
            key: _vm.current_page + "__" + _vm.current_survey_path,
            ref: "survey_container",
            staticClass: "survey-container",
            class: [
              {
                "survey-container--has-background":
                  _vm.current_page !== "survey_code" &&
                  _vm.current_page !== "loading_question" &&
                  _vm.current_page !== "survey_message_only" &&
                  _vm.current_page !== "",
                "survey-container--processing-input": _vm.processing_input,
                "survey-container--error-shake": _vm.error_shake
              },
              "survey-container--page-" + _vm.current_page
            ]
          },
          [
            _vm.messages
              ? _c(
                  "div",
                  { ref: "messages", staticClass: "messages" },
                  [
                    _c(
                      "transition-group",
                      { attrs: { name: "fadeshrink", mode: "out-in" } },
                      _vm._l(_vm.messages, function(message, index) {
                        return _c(
                          "div",
                          {
                            key: message.key,
                            class: "message message--" + message.type,
                            style: message.alert ? "" : "display: none;"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "message__text",
                                attrs: { role: message.alert ? "alert" : "" }
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v(_vm._s(message.type) + " message: ")
                                ]),
                                _c("span", {
                                  domProps: { innerHTML: _vm._s(message.text) }
                                })
                              ]
                            ),
                            _vm._v(" "),
                            message.allow_delete &&
                            _vm.current_page !== "survey_message_only"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "message__close",
                                    attrs: { "aria-label": "close message" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.remove_message(index)
                                      }
                                    }
                                  },
                                  [_vm._v("+")]
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.is_test || _vm.is_admin_override
              ? _c("div", { staticClass: "survey-flags" }, [
                  _vm.is_test
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "survey-flags__tag survey-flags__tag--test"
                        },
                        [_vm._v("Test Mode")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.is_admin_override
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "survey-flags__tag survey-flags__tag--admin-override"
                        },
                        [_vm._v("Admin Override")]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.current_page === "loading_question" || _vm.current_page === ""
              ? _c(
                  "div",
                  { key: "0", staticClass: "survey-question-loading" },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "icon:fa-spinner survey-question-loading__spinner",
                        staticStyle: { transform: "rotate(360deg)" },
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          "xmlns:xlink": "http://www.w3.org/1999/xlink",
                          "aria-hidden": "true",
                          focusable: "false",
                          width: "1em",
                          height: "1em",
                          preserveAspectRatio: "xMidYMid meet",
                          viewBox: "0 0 1664 1728"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M462 1394q0 53-37.5 90.5T334 1522q-52 0-90-38t-38-90q0-53 37.5-90.5T334 1266t90.5 37.5T462 1394zm498 206q0 53-37.5 90.5T832 1728t-90.5-37.5T704 1600t37.5-90.5T832 1472t90.5 37.5T960 1600zM256 896q0 53-37.5 90.5T128 1024t-90.5-37.5T0 896t37.5-90.5T128 768t90.5 37.5T256 896zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5T1202 1394t37.5-90.5t90.5-37.5t90.5 37.5t37.5 90.5zM494 398q0 66-47 113t-113 47t-113-47t-47-113t47-113t113-47t113 47t47 113zm1170 498q0 53-37.5 90.5T1536 1024t-90.5-37.5T1408 896t37.5-90.5T1536 768t90.5 37.5T1664 896zm-640-704q0 80-56 136t-136 56t-136-56t-56-136t56-136T832 0t136 56t56 136zm530 206q0 93-66 158.5T1330 622q-93 0-158.5-65.5T1106 398q0-92 65.5-158t158.5-66q92 0 158 66t66 158z",
                            fill: "currentColor"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _vm.current_page === "survey_code"
              ? _c("div", { key: "1", staticClass: "survey-code" }, [
                  _c("h2", {
                    domProps: {
                      innerHTML: _vm._s(_vm.process_text(_vm.code_input.title))
                    }
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.survey_code,
                        expression: "survey_code"
                      }
                    ],
                    attrs: { placeholder: "Enter Code" },
                    domProps: { value: _vm.survey_code },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.survey_code = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.validate_code_and_start($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.code_input.action_button))]
                  )
                ])
              : _vm.current_page === "survey_consent"
              ? _c("div", { key: "3", staticClass: "survey-consent" }, [
                  _c("div", {
                    staticClass: "survey-consent__question",
                    domProps: {
                      innerHTML: _vm._s(_vm.survey_settings.consent.text)
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "survey-consent__accept" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user_consent_response,
                          expression: "user_consent_response"
                        }
                      ],
                      attrs: {
                        disabled: _vm.consent_given,
                        value: "accept",
                        type: "radio",
                        id: "survey-consent-accept"
                      },
                      domProps: {
                        checked: _vm._q(_vm.user_consent_response, "accept")
                      },
                      on: {
                        change: function($event) {
                          _vm.user_consent_response = "accept"
                        }
                      }
                    }),
                    _c("label", {
                      attrs: { for: "survey-consent-accept" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.survey_settings.consent.label_text__accept
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "survey-consent__reject" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user_consent_response,
                          expression: "user_consent_response"
                        }
                      ],
                      attrs: {
                        disabled: _vm.consent_given,
                        value: "reject",
                        type: "radio",
                        id: "survey-consent-reject"
                      },
                      domProps: {
                        checked: _vm._q(_vm.user_consent_response, "reject")
                      },
                      on: {
                        change: function($event) {
                          _vm.user_consent_response = "reject"
                        }
                      }
                    }),
                    _c("label", {
                      attrs: { for: "survey-consent-reject" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.survey_settings.consent.label_text__reject
                        )
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "question__actions nav-buttons" }, [
                    _c(
                      "button",
                      {
                        staticClass: "back-button",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.prev_question($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.survey_settings.prev_button_text) +
                            "\n                    "
                        )
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "next-button",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submit_consent($event)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.survey_settings.next_button_text) +
                            "\n                    "
                        )
                      ]
                    )
                  ])
                ])
              : _vm.current_page === "survey_start"
              ? _c("div", { key: "4", staticClass: "survey-start" }, [
                  _vm.survey_settings.logo
                    ? _c("div", { staticClass: "survey-start__logo" }, [
                        _c("img", {
                          attrs: {
                            src: _vm.survey_settings.logo,
                            alt: _vm.survey_settings.logo_alt_text
                          }
                        })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h2", [
                    _vm._v(_vm._s(_vm.process_text(_vm.survey_settings.title)))
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.survey_settings.landing_page_text)
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button--start-survey",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.start_survey($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.survey_settings.start_button_text))]
                  )
                ])
              : _vm.current_page === "survey_end"
              ? _c("div", { key: "5", staticClass: "survey-end" }, [
                  _c("h2", [
                    _vm._v(_vm._s(_vm.process_text(_vm.survey_settings.title)))
                  ]),
                  _vm._v(" "),
                  _vm.survey_completed_before_start
                    ? _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.survey_settings.survey_already_complete
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(_vm.survey_settings.thanks_page_text)
                    }
                  })
                ])
              : _vm.current_page === "survey_message_only"
              ? _c("div", { key: "7", staticClass: "survey-message-only" })
              : _c(
                  "div",
                  { key: "6", staticClass: "survey-question" },
                  [
                    _c("question", {
                      attrs: {
                        processing_input: _vm.processing_input,
                        question_data: _vm.question_data,
                        survey_settings: _vm.survey_settings,
                        form_values_preset: _vm.form_values
                      },
                      on: { update_parent_values: _vm.update_form_values }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "question__actions" }, [
                      _c(
                        "button",
                        {
                          staticClass: "back-button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.prev_question($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.survey_settings.prev_button_text) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "next-button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.submit_question($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.survey_settings.next_button_text) +
                              "\n                    "
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }