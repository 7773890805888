var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "question question--type-" + _vm.get_question_type() },
    [
      _c(
        "div",
        {
          directives: [{ name: "scope", rawName: "v-scope" }],
          staticClass: "question__info"
        },
        [
          typeof _vm.question_data.intro_text === "string" &&
          _vm.question_data.intro_text.length > 0
            ? _c("p", { staticClass: "question__intro-text" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.question_data.intro_text) }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "question__text" }, [
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.process_text(_vm.question_data.question.question_text)
                )
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [{ name: "scope", rawName: "v-scope" }],
          staticClass: "question__content"
        },
        [
          _vm.get_question_type() === "textarea"
            ? _c("span", [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form_values,
                      expression: "form_values"
                    }
                  ],
                  attrs: {
                    rows: "7",
                    placeholder:
                      _vm.question_data.question.question_placeholder,
                    disabled: _vm.processing_input
                  },
                  domProps: { value: _vm.form_values },
                  on: {
                    change: _vm.update_parent_values,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.form_values = $event.target.value
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "textfield"
            ? _c("span", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form_values,
                      expression: "form_values"
                    }
                  ],
                  attrs: {
                    type: "text",
                    placeholder:
                      _vm.question_data.question.question_placeholder,
                    placeholder: "Type here",
                    disabled: _vm.processing_input
                  },
                  domProps: { value: _vm.form_values },
                  on: {
                    change: _vm.update_parent_values,
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.form_values = $event.target.value
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "radios"
            ? _c(
                "span",
                _vm._l(_vm.question_data.answer_options_sorted, function(
                  radio
                ) {
                  return _c(
                    "div",
                    {
                      staticClass: "question__radio-group radio-group",
                      class: {
                        "question__radio-group--checked":
                          radio.value.toLowerCase() === _vm.form_values
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form_values.answer,
                            expression: "form_values.answer"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          id: radio.value.toLowerCase(),
                          disabled: _vm.processing_input
                        },
                        domProps: {
                          value: radio.value,
                          checked: _vm._q(_vm.form_values.answer, radio.value)
                        },
                        on: {
                          change: [
                            function($event) {
                              return _vm.$set(
                                _vm.form_values,
                                "answer",
                                radio.value
                              )
                            },
                            _vm.update_parent_values
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio-button" }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "option-click",
                          attrs: { for: radio.value.toLowerCase() }
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(radio.label) }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      radio.request_other &&
                      _vm.form_values.answer === radio.value
                        ? _c(
                            "span",
                            { staticClass: "radio-group__other-box" },
                            [
                              radio.request_other_label
                                ? _c("label", {
                                    attrs: {
                                      for: radio.value.toLowerCase() + "__other"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        radio.request_other_label
                                      )
                                    }
                                  })
                                : _c(
                                    "label",
                                    {
                                      staticClass: "sr-only",
                                      attrs: {
                                        for:
                                          radio.value.toLowerCase() + "__other"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Free input for " + _vm._s(radio.value)
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form_values.answer_other[radio.value],
                                    expression:
                                      "form_values.answer_other[radio.value]"
                                  }
                                ],
                                attrs: {
                                  rows: "3",
                                  id: radio.value.toLowerCase() + "__other",
                                  disabled: _vm.processing_input
                                },
                                domProps: {
                                  value:
                                    _vm.form_values.answer_other[radio.value]
                                },
                                on: {
                                  change: _vm.update_parent_values,
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form_values.answer_other,
                                      radio.value,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "checkboxes"
            ? _c(
                "span",
                _vm._l(_vm.question_data.answer_options_sorted, function(
                  checkbox
                ) {
                  return _c(
                    "div",
                    {
                      staticClass: "question__checkbox-group",
                      class: {
                        "question__checkbox-group--checked": !!_vm.form_values
                          .answer[checkbox.value]
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form_values.answer[checkbox.value],
                            expression: "form_values.answer[checkbox.value]"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: checkbox.value.toLowerCase(),
                          disabled: _vm.processing_input
                        },
                        domProps: {
                          value: checkbox.value,
                          checked: Array.isArray(
                            _vm.form_values.answer[checkbox.value]
                          )
                            ? _vm._i(
                                _vm.form_values.answer[checkbox.value],
                                checkbox.value
                              ) > -1
                            : _vm.form_values.answer[checkbox.value]
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.form_values.answer[checkbox.value],
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = checkbox.value,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form_values.answer,
                                      checkbox.value,
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form_values.answer,
                                      checkbox.value,
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form_values.answer,
                                  checkbox.value,
                                  $$c
                                )
                              }
                            },
                            function(_) {
                              _vm.force_update_val("answer")
                              _vm.update_parent_values()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "option-click",
                          attrs: { for: checkbox.value.toLowerCase() }
                        },
                        [
                          _c("span", {
                            domProps: { innerHTML: _vm._s(checkbox.label) }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      checkbox.request_other &&
                      _vm.showOtherCheckbox(checkbox.value)
                        ? _c(
                            "div",
                            { staticClass: "checkbox-group__other-box" },
                            [
                              checkbox.request_other_label
                                ? _c("label", {
                                    attrs: {
                                      for:
                                        checkbox.value.toLowerCase() + "__other"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        checkbox.request_other_label
                                      )
                                    }
                                  })
                                : _c(
                                    "label",
                                    {
                                      staticClass: "sr-only",
                                      attrs: {
                                        for:
                                          checkbox.value.toLowerCase() +
                                          "__other"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "Free input for " +
                                          _vm._s(checkbox.value)
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form_values.answer_other[
                                        checkbox.value
                                      ],
                                    expression:
                                      "form_values.answer_other[checkbox.value]"
                                  }
                                ],
                                attrs: {
                                  rows: "3",
                                  id: checkbox.value.toLowerCase() + "__other",
                                  disabled: _vm.processing_input
                                },
                                domProps: {
                                  value:
                                    _vm.form_values.answer_other[checkbox.value]
                                },
                                on: {
                                  change: function(_) {
                                    _vm.force_update_val("answer_other")
                                    _vm.update_parent_values()
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form_values.answer_other,
                                      checkbox.value,
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "matrix" ||
          _vm.get_question_type() === "yesno_radios_simple"
            ? _c("span", [
                _c("div", { staticClass: "matrix-container" }, [
                  _c("table", { staticClass: "matrix-question" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("td"),
                          _vm._v(" "),
                          _vm._l(
                            _vm.question_data.answer_options_sorted,
                            function(column) {
                              return _c("td", {
                                domProps: { innerHTML: _vm._s(column.label) }
                              })
                            }
                          )
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { staticClass: "matrix-question__body" },
                      _vm._l(
                        _vm.question_data.question_options_sorted,
                        function(row) {
                          return _c(
                            "tr",
                            [
                              _c(
                                "td",
                                { staticClass: "matrix-question__td--label" },
                                [
                                  _c("span", {
                                    staticClass: "matrix-question__label-text",
                                    domProps: { innerHTML: _vm._s(row.label) }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.question_data.answer_options_sorted,
                                function(column) {
                                  return _c(
                                    "td",
                                    {
                                      staticClass: "matrix-question__td--input",
                                      class: {
                                        "matrix-question__td--input-yes-no":
                                          _vm.get_question_type() ===
                                          "yesno_radios_simple"
                                      }
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "matrix-question__input",
                                          attrs: {
                                            for: row.value + "__" + column.value
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form_values[row.value],
                                                expression:
                                                  "form_values[row.value]"
                                              }
                                            ],
                                            attrs: {
                                              type: "radio",
                                              name:
                                                row.value + "__" + column.value,
                                              id:
                                                row.value + "__" + column.value
                                            },
                                            domProps: {
                                              value: column.value,
                                              checked: _vm._q(
                                                _vm.form_values[row.value],
                                                column.value
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  return _vm.$set(
                                                    _vm.form_values,
                                                    row.value,
                                                    column.value
                                                  )
                                                },
                                                _vm.update_parent_values
                                              ]
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for:
                                                row.value + "__" + column.value
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                row.label + ": " + column.label
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        }
                      ),
                      0
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "matrix_checkboxes"
            ? _c("span", [
                _c("div", { staticClass: "matrix-container" }, [
                  _c("table", { staticClass: "matrix-question" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        [
                          _c("td"),
                          _vm._v(" "),
                          _vm._l(
                            _vm.question_data.answer_options_sorted,
                            function(column) {
                              return _c("td", {
                                domProps: { innerHTML: _vm._s(column.label) }
                              })
                            }
                          )
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { staticClass: "matrix-question__body" },
                      _vm._l(
                        _vm.question_data.question_options_sorted,
                        function(row) {
                          return _c(
                            "tr",
                            [
                              _c(
                                "td",
                                { staticClass: "matrix-question__td--label" },
                                [
                                  _c("span", {
                                    staticClass: "matrix-question__label-text",
                                    domProps: { innerHTML: _vm._s(row.label) }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.question_data.answer_options_sorted,
                                function(column) {
                                  return _c(
                                    "td",
                                    {
                                      staticClass: "matrix-question__td--input"
                                    },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass: "matrix-question__input",
                                          attrs: {
                                            for: row.value + "__" + column.value
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.form_values[row.value][
                                                    column.value
                                                  ],
                                                expression:
                                                  "form_values[row.value][column.value]"
                                              }
                                            ],
                                            attrs: {
                                              type: "checkbox",
                                              name:
                                                row.value + "__" + column.value,
                                              id:
                                                row.value + "__" + column.value
                                            },
                                            domProps: {
                                              value: column.value,
                                              checked: Array.isArray(
                                                _vm.form_values[row.value][
                                                  column.value
                                                ]
                                              )
                                                ? _vm._i(
                                                    _vm.form_values[row.value][
                                                      column.value
                                                    ],
                                                    column.value
                                                  ) > -1
                                                : _vm.form_values[row.value][
                                                    column.value
                                                  ]
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$a =
                                                      _vm.form_values[
                                                        row.value
                                                      ][column.value],
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = column.value,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.form_values[
                                                            row.value
                                                          ],
                                                          column.value,
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.form_values[
                                                            row.value
                                                          ],
                                                          column.value,
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.form_values[
                                                        row.value
                                                      ],
                                                      column.value,
                                                      $$c
                                                    )
                                                  }
                                                },
                                                _vm.update_parent_values
                                              ]
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "sr-only",
                                            attrs: {
                                              for:
                                                row.value + "__" + column.value
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                row.label + ": " + column.label
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          )
                        }
                      ),
                      0
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "ranking"
            ? _c("span", [
                _vm.form_values
                  ? _c(
                      "span",
                      [
                        _c(
                          "draggable",
                          {
                            attrs: { list: _vm.form_values, group: "rankable" },
                            on: {
                              change: _vm.update_parent_values,
                              start: function($event) {
                                _vm.drag = true
                              },
                              end: function($event) {
                                _vm.drag = false
                              }
                            }
                          },
                          [
                            _c(
                              "transition-group",
                              {
                                attrs: { type: "transition", name: "flip-list" }
                              },
                              _vm._l(_vm.form_values, function(rankable) {
                                return _c(
                                  "div",
                                  {
                                    key: rankable.value,
                                    ref: rankable.value,
                                    refInFor: true,
                                    staticClass: "question__ranking",
                                    attrs: { tabindex: "1" },
                                    on: {
                                      keyup: [
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.move_rank_item(
                                            $event,
                                            "up",
                                            rankable
                                          )
                                        },
                                        function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.move_rank_item(
                                            $event,
                                            "down",
                                            rankable
                                          )
                                        }
                                      ]
                                    }
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "rankable-item",
                                      domProps: {
                                        innerHTML: _vm._s(rankable.label)
                                      }
                                    })
                                  ]
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.get_question_type() === "select"
            ? _c("span", [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form_values,
                          expression: "form_values"
                        }
                      ],
                      staticClass: "question__select",
                      attrs: { disabled: _vm.processing_input },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.form_values = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.update_parent_values
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: {
                            value: "",
                            selected: "selected",
                            disabled: "disabled"
                          }
                        },
                        [_vm._v("Select item...")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.question_data.answer_options_sorted, function(
                        opt
                      ) {
                        return _c(
                          "option",
                          { domProps: { value: opt.value } },
                          [_vm._v(_vm._s(_vm.stripHTML(opt.label)))]
                        )
                      })
                    ],
                    2
                  )
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }