export const process_text = function (text_to_process) {
  if (typeof text_to_process === 'string') {
    return text_to_process;
  }
  if (!text_to_process) {
    return '';
  }
  let final_text = text_to_process.text
  if (text_to_process.hasOwnProperty('replacement') && text_to_process.replacement.length > 0) {
    text_to_process.replacement.forEach(function (replace) {
      let search_regex = new RegExp(`${replace.search_term}`);
      final_text = final_text.replace(search_regex, replace.repl)
    })
  }
  return final_text
}

export const stripHTML = function(html) {
  let tmpEl = document.createElement("div");
  tmpEl.innerHTML = html;
  return tmpEl.textContent || tmpEl.innerText || "";
}

export const conditional_log = function() {
  if (this.show_logging) {
    console.log.apply(console, arguments);
    return arguments[0];
  }
}

export const show_error = function (error, allow_delete) {
  if (typeof allow_delete === 'undefined') {
    allow_delete = true;
  }
  if (typeof error === 'string' && error.length > 0) {
    this.messages.push({'type': 'error', 'text': error, allow_delete: allow_delete, 'key': Math.random(), 'alert': true})
  }
  this.$refs.survey_outer.scrollIntoView({
    behavior: "smooth",
    block: 'start',
    inline: 'nearest'
  })
}

export const show_message = function (error, allow_delete) {
  if (typeof allow_delete === 'undefined') {
    allow_delete = true;
  }
  if (typeof error === 'string' && error.length > 0) {
    this.messages.push({'type': 'notice', 'text': error, allow_delete: allow_delete, 'key': Math.random(), 'alert': true})
  }
  this.$refs.survey_outer.scrollIntoView({
    behavior: "smooth",
    block: 'start',
    inline: 'nearest'
  })
}

export const order_options = function (option_type) {
  if (typeof option_type === 'undefined') {
    option_type = 'answer_options_groups'
  }
  let options = [];
  this.question_data.question[option_type].forEach((option_group) => {
    let groups_temp = [];

    if (!option_group.randomize) {
      groups_temp = option_group.children;
    }
    else {
      groups_temp = option_group.children.sort((a, b) => this.seeded_random(b) - this.seeded_random(a))
    }

    groups_temp.forEach((option_sub_group) => {
      if (!option_sub_group.randomize) {
        options = options.concat(option_sub_group.options)
      }
      else {
        options = options.concat(option_sub_group.options.sort((a, b) => this.seeded_random(b) - this.seeded_random(a)))
      }
    })
  })
  return options
}

export const get_question_type = function () {
  return this.question_data && this.question_data.question ? this.question_data.question.question_type : 'none'
}

export const seeded_random = function (data) {
  const data_hash = JSON.stringify(data).split('').reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a
  }, 0);
  return Math.sin(this.random_seed * data_hash)
}


export const get_cookie = function (cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const set_cookie = function (cname, cvalue, days) {
  let d = new Date();
  d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const setScopeRecursively = function(targetEl, scopeId) {
  // This function will recursively travel down from the targetEl and apply the scopeId as a data attribute for CSS scoping.
  // scopeId should be a string (e.g. v-123f1123a)
  try {
    targetEl.setAttribute(`data-${scopeId}`,"")
  } catch(e) {
    console.log(e)
  }

  // Recursively call setScopeRecursively for any children.
  if (targetEl.hasChildNodes()) {
    const list = targetEl.children
    for (var item of list) {
      setScopeRecursively(item, scopeId)
    }
  }
}

export function updateScopeId(el, __, vNode) {
  const vm = vNode.context
  const scopeId = vm & vm.$options._scopeId
  if (scopeId) {
    vm.$nextTick(() => { // wait till DOM was updated
      setScopeRecursively(el, `v-${scopeId}`)
    })
  }
}

export const handleErrors = function(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
