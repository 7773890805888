<template>
    <div :class="'question question--type-' + get_question_type()">
        <div class="question__info" v-scope>
            <p v-if="typeof question_data.intro_text === 'string' && question_data.intro_text.length > 0" class="question__intro-text"><span v-html="question_data.intro_text"></span></p>
            <p class="question__text"><span v-html="process_text(question_data.question.question_text)"></span></p>
        </div>
        <div class="question__content" v-scope>
            <span v-if="get_question_type() === 'textarea'">
                            <textarea @change="update_parent_values" rows="7" v-model="form_values"
                                      v-bind:placeholder="question_data.question.question_placeholder" :disabled="processing_input"></textarea>
                        </span>
            <span v-if="get_question_type() === 'textfield'">
                            <input @change="update_parent_values" type="text" v-model="form_values"
                                      v-bind:placeholder="question_data.question.question_placeholder" placeholder="Type here" :disabled="processing_input">
                        </span>
            <span v-if="get_question_type() === 'radios'">
                <div v-for="radio in question_data.answer_options_sorted" class="question__radio-group radio-group" :class="{'question__radio-group--checked': radio.value.toLowerCase() === form_values}">
                    <input @change="update_parent_values" type="radio" v-bind:id="radio.value.toLowerCase()" v-model="form_values.answer"
                           v-bind:value="radio.value" :disabled="processing_input">
                    <span class="radio-button"></span>
                    <label class="option-click" v-bind:for="radio.value.toLowerCase()"><span v-html="radio.label"></span></label>
                    <span v-if="radio.request_other && form_values.answer === radio.value" class="radio-group__other-box">
                        <label v-if="radio.request_other_label" :for="radio.value.toLowerCase() + '__other'" v-html="radio.request_other_label"></label>
                        <label v-else class="sr-only" :for="radio.value.toLowerCase() + '__other'">Free input for {{radio.value}}</label>
                        <textarea @change="update_parent_values" rows="3" v-bind:id="radio.value.toLowerCase() + '__other'" v-model="form_values.answer_other[radio.value]"
                                :disabled="processing_input"></textarea>
                    </span>
                </div>
            </span>
            <span v-if="get_question_type() === 'checkboxes'">
                <div v-for="checkbox in question_data.answer_options_sorted" class="question__checkbox-group" :class="{'question__checkbox-group--checked': !!form_values.answer[checkbox.value]}">
                    <input @change="_ => {force_update_val('answer'); update_parent_values();}" type="checkbox" v-bind:id="checkbox.value.toLowerCase()"
                            v-model="form_values.answer[checkbox.value]" v-bind:value="checkbox.value" :disabled="processing_input">
                    <label class="option-click" v-bind:for="checkbox.value.toLowerCase()"><span v-html="checkbox.label"></span></label>
                    <div v-if="checkbox.request_other && showOtherCheckbox(checkbox.value)" class="checkbox-group__other-box">
                        <label v-if="checkbox.request_other_label" :for="checkbox.value.toLowerCase() + '__other'" v-html="checkbox.request_other_label"></label>
                        <label v-else class="sr-only" :for="checkbox.value.toLowerCase() + '__other'">Free input for {{checkbox.value}}</label>
                        <textarea @change="_ => {force_update_val('answer_other');  update_parent_values();}" rows="3" v-bind:id="checkbox.value.toLowerCase() + '__other'"
                                  v-model="form_values.answer_other[checkbox.value]" :disabled="processing_input"></textarea>
                    </div>
                </div>
            </span>
            <span v-if="get_question_type() === 'matrix' || get_question_type() === 'yesno_radios_simple'">
                <div class="matrix-container">
                <table class="matrix-question">
                    <thead>
                        <tr>
                            <td></td>
                            <td v-for="column in question_data.answer_options_sorted" v-html="column.label"></td>
                        </tr>
                    </thead>
                    <tbody class="matrix-question__body">
                        <tr v-for="row in question_data.question_options_sorted">
                            <td class="matrix-question__td--label">
                               <span class="matrix-question__label-text" v-html="row.label"></span>
                            </td>
                            <td class="matrix-question__td--input" :class="{'matrix-question__td--input-yes-no': get_question_type() === 'yesno_radios_simple'}" v-for="column in question_data.answer_options_sorted">
                                <label :for="row.value + '__' + column.value" class="matrix-question__input">
                                  <input @change="update_parent_values" type="radio" :name="row.value + '__' + column.value" v-bind:id="row.value + '__' + column.value" v-model="form_values[row.value]" v-bind:value="column.value">
                                  <span class="sr-only" :for="row.value + '__' + column.value" v-html="row.label + ': ' + column.label"></span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </span>
            <span v-if="get_question_type() === 'matrix_checkboxes'">
                <div class="matrix-container">
                <table class="matrix-question">
                    <thead>
                        <tr>
                            <td></td>
                            <td v-for="column in question_data.answer_options_sorted" v-html="column.label"></td>
                        </tr>
                    </thead>
                    <tbody class="matrix-question__body">
                        <tr v-for="row in question_data.question_options_sorted">
                            <td class="matrix-question__td--label">
                              <span class="matrix-question__label-text" v-html="row.label"></span>
                            </td>
                            <td class="matrix-question__td--input" v-for="column in question_data.answer_options_sorted">
                                <label :for="row.value + '__' + column.value" class="matrix-question__input">
                                    <input @change="update_parent_values" type="checkbox" :name="row.value + '__' + column.value" v-bind:id="row.value + '__' + column.value" v-model="form_values[row.value][column.value]" v-bind:value="column.value">
                                    <span class="sr-only" :for="row.value + '__' + column.value" v-html="row.label + ': ' + column.label"></span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </span>
            <span v-if="get_question_type() === 'ranking'">
                <span v-if="form_values">
                  <draggable @change="update_parent_values"  :list="form_values" group="rankable" @start="drag=true" @end="drag=false">
                    <transition-group type="transition" :name="'flip-list'">
                      <div :ref="rankable.value" tabindex="1" v-on:keyup.up="move_rank_item($event, 'up', rankable)" v-on:keyup.down="move_rank_item($event, 'down', rankable)" v-for="rankable in form_values" :key="rankable.value" class="question__ranking">
                         <span class="rankable-item" v-html="rankable.label"></span>
                       </div>
                    </transition-group>
                  </draggable>
                </span>
            </span>
            <span v-if="get_question_type() === 'select'">
                <div class="dropdown">
                <select v-model="form_values" @change="update_parent_values" class="question__select" :disabled="processing_input">
                    <option value="" selected="selected" disabled="disabled">Select item...</option>
                    <option v-for="opt in question_data.answer_options_sorted" :value="opt.value">{{stripHTML(opt.label)}}</option>
                </select>
                </div>
            </span>
        </div>
    </div>
</template>

<script>
  import draggable from "vuedraggable";
  import {
    conditional_log,
    get_question_type,
    order_options,
    process_text,
    seeded_random,
    stripHTML
  } from "../utilities";

  export default {
    components: {
      draggable,
    },
    data: () => {return {
      form_values: null
    }},
    created() {
      if (this.get_question_type() === "ranking") {
        this.form_values = this.question_data.answer_options_sorted;
        if (this.existing_preset()) {
          this.form_values = this.form_values_preset
        }
      }
      else if (this.get_question_type() === "checkboxes") {
        this.form_values = {
          answer_other: {},
          answer: {},
        };
        if (this.existing_preset()) {
          this.form_values.answer = this.form_values_preset.answer
          if (typeof this.form_values.answer_other !== 'undefined') {
            this.form_values.answer_other = this.form_values_preset.answer_other
          }
        }
      }
      else if (this.get_question_type() === "matrix" || this.get_question_type() === "yesno_radios_simple") {
        this.form_values = {};
        if (this.existing_preset()) {
          this.form_values = this.form_values_preset
        }
      }
      else if (this.get_question_type() === "matrix_checkboxes") {
        if (this.existing_preset()) {
          this.form_values = this.form_values_preset
        }
        else {
          this.form_values = {};
          this.question_data.question_options_sorted.forEach((row) => {
            this.form_values[row.value] = {};
            this.question_data.answer_options_sorted.forEach((column) => {
              this.form_values[row.value][column.value] = false;
            })
          })
        }
      }
      else if (this.get_question_type() === "radios") {
        this.form_values = {
          answer_other: {},
          answer: '',
        };
        if (this.existing_preset()) {
          this.form_values.answer = this.form_values_preset.answer
          if (typeof this.form_values.answer_other !== 'undefined') {
            this.form_values.answer_other = this.form_values_preset.answer_other
          }
        }
      }
      else {
        if (this.existing_preset()) {
          this.form_values = this.form_values_preset
        }
      }
      this.update_parent_values()
    },
    props: {question_data: Object, survey_settings: Object, processing_input: Boolean, form_values_preset: [String, Object, Array]},
    methods: {
      existing_preset: function() {
        return !(this.form_values_preset === null || this.form_values_preset === '' || (typeof this.form_values_preset === 'object' && Object.keys(this.form_values_preset).length === 0))
      },
      process_text: process_text,
      order_options: order_options,
      log: conditional_log,
      get_question_type: get_question_type,
      seeded_random: seeded_random,
      stripHTML: stripHTML,
      showOtherCheckbox: function(val) {
        return typeof this.form_values.answer[val] && Boolean(this.form_values.answer[val]);
      },
      update_parent_values: function() {
        this.$emit('update_parent_values', this.form_values)
      },
      force_update_val: function(val) {
        this.log('force update', val, this.form_values[val])
        this.$set(this.form_values, val, this.form_values[val])
      },
      move_rank_item: function(e, dir, item) {
        const index_to_move = this.form_values.findIndex((i) => i.value === item.value);

        const switch_index = dir === 'up' ? index_to_move - 1 : index_to_move + 1;
        if (switch_index >= this.form_values.length || switch_index < 0) {
          this.log("Can't go further in that direction.")
          return;
        }

        let orig = this.form_values[switch_index];
        this.form_values[switch_index] = this.form_values[index_to_move]
        this.$set(this.form_values, index_to_move, orig)
        this.$nextTick(function(){
          this.$refs[item.value][0].focus();
        });

      },
    },
  }
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
    .flip-list-move {
      transition: transform 0.5s;
    }

    body {
        margin: 0;
        padding: 0;
    }

    /* Page Layout and Text Element Styling */

    p {
        margin: 0;
        padding: 0;
        letter-spacing: 0.5pt;
        color: #343333;
    }

    .question__content p {
        color: #343333;
        font-weight: normal;
    }

    .question__intro-text p {
        margin: 10px 0;
        text-align: left;
        font-size: 22px;
    }

    .question__text p {
        margin: 10px 0;
        text-align: left;
        font-size: 22px;
    }


    .question__content p.description {
      font-size: 16px;
      font-weight: normal;
      color: #626262;
    }

    .question__text p.description {
        font-weight: normal;
        color: #626262;
        font-size: 18px;
    }

    .survey-start p {
        margin: 1em 0;
    }

    .matrix-question thead p {
        font-weight: 600;
        margin: 10px 0;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.25em;
    }

    h3 {
        font-size: 1.15em;
    }

    h1, h2, h3, h4, h5 {
        color: #343333;
        margin: 10px 0;
        padding: 0;
        letter-spacing: normal;
    }

    /* Ranking List */

    .rankable-item p::before {
        content: "\2195 ";
        font-size: 30px;
        padding-right: 10px;
    }

    .rankable-item p {
        display: table-cell;
        vertical-align: middle;
    }

    .sr-only {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
    }


/* Tablet and Desktop */
@media (min-width: 600px) {
    body {
        background-color: #f2f2f2;
        font-size: 18px;
    }
}

@media (min-width: 800px) {
  .matrix-question__td--input-yes-no {
    width: 78px;
  }
}

/* Mobile */

@media (max-width: 600px) {
    body {
        background-color: #ffffff;
        font-size: 18px;
    }
}

</style>

<style>

    /* Text Boxes */

    textarea {
        width: 100%;
        box-sizing: border-box;
        resize: none;
    }

    .question__content input[type=text] {
        max-width: 500px;
    }

    .question__content input[type=text], textarea {
        padding: 8px 12px;
        font-family: Verdana, 'Open Sans', sans-serif;
        color: #343333;
        border-radius: 10px;
        border: 1px solid #1172ba;
        width: 100%;
        box-sizing: border-box;
        outline: none;
    }

    .question__content input[type=text]:focus, textarea:focus, select.question__select:focus {
        transition: all 200ms ease-out;
        box-shadow: 0 4px 8px 0 rgba(17, 114, 186, 0.2), 0 6px 20px 0 rgba(17, 114, 186, 0.19);
    }

    .question__content input::placeholder {
        color: #626262;
    }

    /* Select List */
    select.question__select {
        padding: 8px 12px;
        letter-spacing: 0.5pt;
        font-family: Verdana, 'Open Sans', sans-serif;
        color: #343333;
        border-radius: 10px;
        border: 1px solid #1172ba;
        width: 100%;
        box-sizing: border-box;
        max-width: 500px;
        background-color: #fff;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
    }

    .dropdown::after {
        content: '';
        position: relative;
        margin-left: -25px;
        right: 12px;
        border: solid #1172ba;
        border-width: 0 5px 5px 0;
        display: inline-block;
        padding: 8px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        pointer-events: none;
        border-radius: 1px;
    }

    /* Rankable List */
    .question__ranking {
        cursor: pointer;
    }

    /* Radio Buttons / Check Boxes */

    .question__checkbox-group, .question__radio-group {
        display: block;
        position: relative;
        margin-bottom: 10px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-left: 35px;
        color: #343333;
    }
    .question__checkbox-group input[type='checkbox'], .question__radio-group input[type='radio'] {
        position: absolute;
    }

    /* Make button area clickable for checkboxes and radio buttons */

    .option-click {
        width: 105%;
        box-sizing: border-box;
        display: block;
        margin-left: -35px;
        padding-left: 35px;
    }

    /* Matrix Questions */
    .matrix-container {
        max-width: 100%;
        box-sizing: border-box;
        overflow-y: hidden;
        overflow-x: auto;
    }

    table.matrix-question {
        border: none;
        border-spacing: 0;
        text-align: center;
    }

    .matrix-question thead td {
        padding: 5px 10px;
    }

    .matrix-question__td--label {
        min-width: 150px;
        text-align: left;
    }

    .matrix-question__body tr:nth-child(odd) {
        background-color: #f2f2f2;
    }

    .matrix-question__input {
        width: inherit;
        display: block;
        cursor: pointer;
        padding: 16px 10px;
        /*margin: -10px;*/
    }

    table.matrix-question {
      width: 100%;
    }

    .matrix-question__td--label {
      padding: 16px 16px;
    }
    .matrix-question__td--label p {
      margin: 6px 0;
    }
    .matrix-question__td--label p:last-child {
      margin-bottom: 0;
    }
    .matrix-question__td--label p:first-child {
      margin-top: 0;
    }
    .checkbox-group__other-box {
      margin-top: 6px;
    }

    /* Custom Radio Buttons */
    /* Hide the browser's default radio button
    .question__checkbox-group input, .question__radio-group input {
        //z-index: 10;
        //opacity: 0;
    }*/

    /* Create a custom radio button
    .radio-button {
        top: 5px;
        left: 5px;
        position: absolute;
        height: 14px;
        width: 14px;
        border: 2px solid #333;
        border-radius: 50%;
    }*/

    /* On mouse-over, turn blue
    .question__radio-group:hover .radio-button {
        background-color: #1172ba;
        border: 2px solid #1172ba;
    }*/

    /* When the radio button is checked, turn circle blue
    .question__radio-group input:checked ~ .radio-button {
        background-color: #1172ba;
        border: 2px solid #1172ba;
    }*/

    /* Create the indicator (the dot/circle - hidden when not checked)
    .radio-button:after {
        content: "";
        position: absolute;
        display: none;
    } */

    /* Show the indicator (dot/circle) when checked
    .question__radio-group input:checked ~ .radio-button:after {
            display: block;
    } */

    /* Style the indicator (dot/circle)
    .question__radio-group .radio-button:after {
        top: 4px;
        left: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: white;
    } */

/* Tablet and Desktop */
@media (min-width: 600px) {
    /* Page Layout and Text Element Styling*/

    .question__content {
        margin: 20px 0;
        font-size: 18px;
    }

    .question__text p {
        font-size: 22px;
        margin: 10px 0;
    }

    /* Text Boxes */

    .question__content input[type=text], textarea {
        font-size: 18px;
    }

    /* Select List */

    select.question__select {
        font-size: 18px;
         /* Line */
        background-image: linear-gradient(to right, #1172ba, #1172ba);
        background-position: calc(100% - 2.7em) 0em;
        background-size: 1px 2.5em;
        background-repeat: no-repeat;
    }

    /* Ranked List */

    .question__ranking {
        margin: 10px 0;
    }

    /* Radio Buttons / Check Boxes */
    .question__checkbox-group input, .question__radio-group input {
        left: 3px;
        top: 4px;
    }

}
/* Mobile */

@media (max-width: 600px) {

    /* Survey Start */

    .survey-start p {
        text-align: center;
    }

    /* Page Layout and Text Element Styling*/

    .question__content {
        margin: 20px 0;
        font-size: 18px;
    }

    .question__text p {
        margin: 20px 0;
        font-size: 22px;
    }

    /* Text Boxes */
    .question__content input[type=text], .question__select {
        border-radius: 5px;
    }

    .question__content input[type=text], textarea {
        font-size: 20px;
    }

    /* Select List */
    select.question__select {
        font-size: 20px;
        border-radius: 5px;
    }

    /* Ranking List */

    .question__ranking {
        border-radius: 5px;
        background-color: #f4f3f3;
        padding: 10px;
        text-align: left;
        margin: 20px 0;
    }

    /* Radio Buttons / Checkboxes */
    .question__checkbox-group, .question__radio-group {
        border-radius: 5px;
        background-color: #f4f3f3;
        padding: 10px;
        padding-left: 50px;
        text-align: left;
        margin-bottom: 12px;
    }

    /*.question__checkbox-group input {*/
    /*  left: 15px;*/
    /*}*/
    .question__checkbox-group input[type="checkbox"],
    .question__radio-group input[type="radio"] {
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 0 0 3px;
    }

    .question__checkbox-group--checked, .question__radio-group--checked {
        background-color: #c4dcee;
    }

    /* Matrix Questions */
    .matrix-question__td--label {
        padding: 8px 12px;
        font-size: 18px;
    }

    .matrix-question__input {
      padding: 10px 10px;
    }

    /* custom radio button
    .question__radio-group input:checked {
        background-color: #c4dcee;
    }

    .radio-button {
        height: 22px;
        width: 22px;
        top: 14px;
        left: 12px;
    }

    .question__radio-group .radio-button:after {
        top: 6px;
        left: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: white;
    }*/
}
</style>
